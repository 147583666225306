<template>
  <div class="card p-shadow-6">
    <InstallationChangeTechnician
      :showToast="true"
      :show="installationTechnicianInfo.show"
      :installationId="installationTechnicianInfo.installationId"
      :currentTechnician="installationTechnicianInfo.currentTechnician"
      v-on:cancel="changeTechnicanCancel"
      v-on:setTechnicianInstallation="changedTechnican"
    />
    <h1>Instalações sem Relatório</h1>
    <Divider />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-2">
        <Button
          label="Ver Relatórios das Instalações"
          class="p-button-success p-button-outlined"
          @click="goToInstallationWitchReports"
        />
      </div>
    </div>
    <Divider />
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <DataTable
      :value="installationsList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
      sortField="name"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="id"
        header="Id"
        sortable
        :headerStyle="{ width: '170px' }"
        filterField="id"
      >
        <template #body="slotProps">
          <span class="p-column-title">Id:</span>
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          <span class="p-column-title">Nome:</span>
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="description"
        header="Descrição"
        sortable
        filterField="description"
      >
        <template #body="slotProps">
          <span class="p-column-title">Descrição:</span>
          {{ slotProps.data.description }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="seller" header="Vendedor" sortable filterField="seller">
        <template #body="slotProps">
          <span class="p-column-title">Vendedor:</span>
          {{ slotProps.data.seller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="manager" header="Gestor" sortable filterField="manager">
        <template #body="slotProps">
          <span class="p-column-title">Gestor:</span>
          {{ slotProps.data.manager }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="responsible_technical"
        header="Responsável"
        sortable
        filterField="responsible_technical"
      >
        <template #body="slotProps">
          <span class="p-column-title">Responsável:</span>
          {{ slotProps.data.responsible_technical }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="technician"
        header="Técnico Res."
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="has_installation_sheet"
        header="Folha Instalação"
        sortable
        filterField="has_installation_sheet"
        dataType="boolean"
      >
        <template #body="slotProps">
          <span class="p-column-title">Folha Instalação:</span>
          {{ slotProps.data.has_installation_sheet == true ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column :headerStyle="{ width: '140px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver comentários'"
            icon="pi pi-comments"
            class="p-button-sm p-button-rounded p-button-secondary p-button-outlined p-mr-1"
            @click="viewComments(slotProps.data.id)"
          />
          <Button
            v-tooltip.left="'Inserir link para o Relatório'"
            icon="pi pi-link"
            class="p-button-rounded p-button-success p-button-outlined p-ml-1"
            @click="showLinkForm(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.has_installation_sheet"
            v-tooltip.left="'Ir para folha de instalação'"
            icon="pi pi-book"
            class="p-button-rounded p-button-info p-button-outlined p-ml-1"
            @click="
              $router.push(`/task-installation-sheet/${slotProps.data.id}/1`)
            "
          />
          <Button
            v-if="
              loggedUser.isEsa ||
              loggedUser.isAdmin ||
              loggedUser.username == slotProps.data.technician
            "
            v-tooltip.left="'Trocar Técnico Responsável pelo Survey'"
            icon="pi pi-users"
            class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
            @click="changeTechnican(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar link do relatório'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="installationForm"
        class="p-col-12"
        @submit.prevent="setReportLink"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <p><b>Nome da Instalação: </b>{{ linkToInstallation.name }}</p>
            <p>
              <b>Descrição da Instalação: </b
              >{{ linkToInstallation.description }}
            </p>

            <span class="p-float-label p-mt-1">
              <InputText
                type="text"
                v-model="linkToInstallation.reportLink"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has(
                      'installation-' + linkToInstallation.id
                    ),
                  },
                  'form-control',
                ]"
                :id="'installation-' + linkToInstallation.id"
                :name="'installation-' + linkToInstallation.id"
              />
              <label for="pats">Link para o relatório</label>
            </span>
            <small
              v-if="errors.has('installation-' + linkToInstallation.id)"
              class="p-error"
              role="alert"
            >
              O Link para o relatório é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="setReportLink"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import installationsService from "../services/installations.service";
import { FilterMatchMode } from "primevue/api/";
import InstallationChangeTechnician from "../components/installations/ChangeTechnician.vue";

export default {
  name: "InstallationsWitchoutReports",
  components: {
    InstallationChangeTechnician,
  },
  data() {
    return {
      loading: true,
      installationsList: [],
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        manager: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsible_technical: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        has_installation_sheet: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
      showForm: false,
      linkToInstallation: {},
      installationTechnicianInfo: {
        show: false,
        installationId: null,
        currentResponsable: 0,
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.loading = true;
    this.getInstallations();
  },

  methods: {
    getInstallations() {
      this.loading = true;
      return installationsService
        .getInstallationWitchoutReport()
        .then((response) => {
          this.loading = false;
          return (this.installationsList = response);
        });
    },
    showLinkForm(installation) {
      this.showForm = true;
      this.linkToInstallation = installation;
      this.linkToInstallation.reportLink = "";
    },
    closeForm() {
      this.showForm = false;
      this.linkToInstallation = {};
    },
    setReportLink() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        this.setLink();
      });
    },
    setLink() {
      const areYou = (installationsAux) => {
        return installationsAux.id == this.linkToInstallation.id;
      };

      let bodyParms = {
        url: this.linkToInstallation.reportLink,
      };
      installationsService
        .setReportUrl(this.linkToInstallation.id, bodyParms)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o link na instalação",
              life: 3000,
            });
          }

          let installationsIndex = this.installationsList.findIndex(areYou);

          if (installationsIndex >= 0) {
            this.installationsList.splice(installationsIndex, 1);
          }

          this.closeForm();
          return this.$toast.add({
            severity: "success",
            summary: "Relatório da instalação",
            detail: "Relatório da instalação adicionado com sucesso",
            life: 3000,
          });
        });
    },
    goToInstallationWitchReports() {
      this.$router.push(`/installations-witch-report`);
    },
    viewComments(installationsId) {
      let route = this.$router.resolve({
        path: `/project-comments-view/${installationsId}`,
      });
      window.open(route.href, "_blank");
    },
    changeTechnican(installation) {
      this.installationTechnicianInfo.show = true;
      this.installationTechnicianInfo.installationId = installation.id;
      this.installationTechnicianInfo.currentTechnician =
        installation.technician;
      this.installationTechnicianInfo.installation = installation;
    },
    changeTechnicanCancel() {
      return (this.installationTechnicianInfo = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
    changedTechnican(response) {
      if (response.status == "ok") {
        this.installationTechnicianInfo.installation.technician =
          response.currenTechnican;
      }
      return (this.installationTechnicianInfo = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.p-column-filter {
  width: 100%;
}
</style>
